/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ButtonLink } from "./InputStyles";
import { useTranslation } from 'react-i18next';
import logo from '../../assets/Remppa_Vasara.png';
import { Box, Grid } from '@mui/material';

const Header: React.FC = () => {

  const { t, i18n } = useTranslation()

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
      <header>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={5} md={5} display="flex" alignItems="center" justifyContent="left">
              <img src={logo} alt='Logo' style={{width: "100%", verticalAlign: "middle"}}/>
            </Grid>
            <Grid item xs={4} md={4} display="flex" alignItems="center">
              <span style={{fontSize: `${tokens.values.typography.leadText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit}`}}>{t("app.header.title")}</span>
            </Grid>
            <Grid item xs={3} md={3} display="flex" alignItems="center" justifyContent="right">
              <ButtonLink
                  style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
                  onClick={() => changeLanguage('fi') }>
                FI
              </ButtonLink>
              <ButtonLink
                  style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
                  onClick={() => changeLanguage('en') }>
                EN
              </ButtonLink>
            </Grid>
          </Grid>
        </Box>
      </header>
  );
};

export default Header;
