import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { AppStateContext } from '../state/AppStateContext';
import QRCode from 'qrcode.react';
import QrReader from './QrReader'
import { Paragraph, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import * as AcaPyTypes from '../models/AcaPyModels';
import { FlexColTight } from './common/CommonStyles';

const Line = styled(Paragraph)`
  text-align: center;
  border-bottom: 1px solid ${tokens.colors.depthBase};
  line-height: 0.1em;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

const Span = styled.span`
  background: ${tokens.colors.whiteBase};
  padding: 0 10px;
  color: ${tokens.colors.depthDark1};
  font-size: 0.9rem;
  margin-right: 0%;
  font-weight: 500;
`

enum ConnMethod {
  USE_EXISTING,
  CREATE_NEW,
  PUBLIC_DID
}

enum ConnError {
  GENERIC = 1,
  INVALID = 2
}

const ConnStateContent: React.FC<{connection: AcaPyTypes.ConnRecord}> = ({connection}) => {
  const { t } = useTranslation(["translation"]);
  switch (connection.state) {
    case "active":
      return (
        <Alert severity="success" >
          <AlertTitle>{t("app.components.Receipt.connectionCreatedTitle")}</AlertTitle>
          {t("app.components.Receipt.connectionCreatedMsg")}
        </Alert>
      );
    case "abandoned":
      return (
        <Alert severity="error">
          <AlertTitle>{t("app.components.Receipt.errorTitle")}</AlertTitle>
          {t("app.components.Receipt.connectionErrorMsg")}
        </Alert>
      );
    default:
      return (
        <Alert severity="info" icon={<CircularProgress />} >
          <AlertTitle>{t("app.components.Receipt.connectionPendingTitle")}</AlertTitle>
          {t("app.components.Receipt.connectionPendingMsg")}
        </Alert>
      );
  }
}

const Invitation: React.FC<{invitation: AcaPyTypes.InvitationResult}> = ({invitation}) => {
  const { t } = useTranslation(["translation"]);
  const [showInvitationUrl, setShowInvitationUrl] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  }

  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{pb: 2}}>
        {t("app.components.Receipt.scanInvitationHelpText")}
      </Typography>
      <Box sx={{m: "auto"}}>
        <QRCode size={250} value={invitation.invitation_url!} style={{marginRight: `${tokens.spacing.xs}`}}/>
      </Box>
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line>
      <Typography variant="body1" gutterBottom sx={{m: "auto"}}>
        {t("app.components.Receipt.copyInvitationHelpText")}
        <Button variant="text" onClick={() => setShowInvitationUrl(old => !old)}>
          { showInvitationUrl ?
            t("app.components.Receipt.hideInvitationUrl") :
            t("app.components.Receipt.showInvitationUrl")
          }
        </Button>
      </Typography>
      { showInvitationUrl && 
        <TextField multiline fullWidth variant={"filled"} value={invitation.invitation_url!} style={{backgroundColor: `${tokens.colors.whiteBase}`}} sx={{m: 0}}/>
      }
      <Button
        variant="contained"
        onClick={() => copyToClipboard(invitation.invitation_url!)}
        sx={{ mt: 1, mx: "auto" }}
      >{isCopied ? t("app.components.Receipt.urlCopied") : t("app.components.Receipt.copyUrl")}</Button>
    </FlexColTight>
  )
}

const SelectConnectionMethod: React.FC<{onSelect: (method: ConnMethod) => void}> = ({onSelect}) => {
  const { t } = useTranslation(["translation"]);
  
  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{pb: 2}}>
        {t("app.components.Receipt.selectConnectionHelpText")}
      </Typography>
      {/* <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(ConnMethod.USE_EXISTING)}>
        {t("app.components.Receipt.useExistingConnectionBtn")}
      </Button>
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line> */}
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(ConnMethod.CREATE_NEW)}>
        {t("app.components.Receipt.createNewConnectionBtn")}
      </Button>
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line>
      <Button variant="contained" sx={{ mx: "auto" }} onClick={() => onSelect(ConnMethod.PUBLIC_DID)}>
        {t("app.components.Receipt.usePublicDIDBtn")}
      </Button>
    </FlexColTight>
  );
}

const UseExistingConnection: React.FC = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{ pb: 2}}>
        {t("app.components.Receipt.featureNotImplemented")}
      </Typography>
    </FlexColTight>
  );
}

const CreateNewConnection: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = React.useContext(AppStateContext);

  useEffect(() => {
    if (!appContext.invitation) {
      appContext.createInvitationAsync();
    }
  }, []);

  if (appContext.invitation) {
    return (
      <Invitation invitation={appContext.invitation} />
    );
  }
  return (
    <Alert severity="info" icon={<CircularProgress />} >
      <AlertTitle>{t("app.components.Receipt.creatingInvitationTitle")}</AlertTitle>
      {t("app.components.Receipt.creatingInvitationMsg")}
    </Alert>
  );
}

const UsePublicDIDConnection: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = React.useContext(AppStateContext);
  const [openReader, setOpenReader] = useState<boolean>(false);
  const [publicDID, setPublicDID] = useState<string>("");

  const onConfirm = (did: string) => {
    appContext.getCreatePublicDidConnection(did);
  }

  return (
    <FlexColTight>
      <Typography variant="body1" gutterBottom sx={{m: "auto", pb: 2}}>
        {t("app.components.Receipt.usePublicDIDHelpText")}
      </Typography>
      { openReader ? 
        <QrReader
          onRead={(res: string) => {
            setPublicDID(res)
            onConfirm(res)
          }}
        />
        :
        <Button
          variant="contained"
          startIcon={<CameraAltIcon/>}
          onClick={() => setOpenReader(true)}
          sx={{ mt: 1 }}
        >{t("app.components.AddConnection.openCamera")}</Button>
      }
      <Line>
        <Span>{t("app.components.Receipt.OR")}</Span>
      </Line>
      <TextField
        value={publicDID}
        onChange={(e) => setPublicDID(e.target.value)}
      />
      <Button
        variant="contained"
        onClick={() => onConfirm(publicDID)}
        sx={{ mt: 1 }}
      >{t("app.components.Receipt.confirm")}</Button>
    </FlexColTight>
  );
}

const Receipt: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = React.useContext(AppStateContext);
  const [error, setError] = useState<number | undefined>(undefined);
  const [connMethod, setConnMethod] = useState<ConnMethod | undefined>(undefined);

  const selectConnMethod = (method: ConnMethod) => {
    setConnMethod(method);
  }

  const resetConnMethod = () => {
    // Delete possible invitation if needed.
    setConnMethod(undefined);
  }

  if (appContext.walletError) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" gutterBottom>
          {t("app.components.Receipt.title")}
        </Typography>
        <Alert severity="error">
          <AlertTitle>{t("app.components.Receipt.errorTitle")}</AlertTitle>
          {t(`app.components.AppStateContext.WalletError.${appContext.walletError}`)}
        </Alert>
      </Container>
    );
  }
  
  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        {t("app.components.Receipt.title")}
      </Typography>
      { appContext.connection ? 
        <ConnStateContent connection={appContext.connection} />
        :
        <>
          { connMethod === undefined && <SelectConnectionMethod onSelect={selectConnMethod}/>}
          { connMethod === ConnMethod.USE_EXISTING && <UseExistingConnection/>}
          { connMethod === ConnMethod.CREATE_NEW && <CreateNewConnection/>}
          { connMethod === ConnMethod.PUBLIC_DID && <UsePublicDIDConnection/>}
          { connMethod !== undefined &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() => resetConnMethod()}
              >
                {t("common.actions.cancel.title")}
              </Button>
            </Box>
          }
        </>
      }
    </Container>
  );
}

export default Receipt;