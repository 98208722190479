import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Button, CircularProgress, Container, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "./common/Card";
import Layout from "./common/Layout";
import LoadingModal from "./modals/LoadingModal";
import Review from "./Review";
import { ConnRecord, V10CredentialExchange } from "../models/AcaPyModels";
import { Purchase } from "../models/PurchaseModels";
import * as StoreWalletApi from '../api/StoreWalletApi';
import Receipt from "./Receipt";
import { AppStateContext } from "../state/AppStateContext";

const initPurchase: () => Purchase = () => {
  return {
    items: [
      {
        product: {
          sku: "hammer",
          name: "Hammer",
          grossPrice: "19.95",
          vatPercentage: "24"
        },
        amount: 1
      },
      {
        product: {
          sku: "handsaw",
          name: "Hand saw",
          grossPrice: "9.95",
          vatPercentage: "24"
        },
        amount: 1
      }
    ],
    paymentDetails: {

    }
  };
}

const NewPurchase: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const [activeStep, setActiveStep] = React.useState(0);
  const appContext = React.useContext(AppStateContext);
  const [purchase, setPurchase] = useState<Purchase>(initPurchase());
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [credential, setCredential] = useState<V10CredentialExchange|undefined>(undefined);
  const steps = [
    "AddProducts",
    "Receipt", 
    "Review"
  ];

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      submitPurchase();
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const submitPurchase = () => {
    if (appContext.connection?.connection_id) {
      setSubmitting(true);
      setError(false);
      StoreWalletApi.SubmitPurchase(appContext.connection.connection_id, purchase)
      .then(res => {
        if (!res.data) {
          throw new Error();
        }
        console.log("Credential", res.data);
        setCredential(res.data);
      })
      .catch(err => {
        setError(true);
      })
      .finally(() => setSubmitting(false));
    }
  }

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        // Not in use, just a placeholder. Skip automatically to the next step.
        setActiveStep(1);
        return <></>;
      case 1:
        return <Receipt/>;
      case 2:
        return <Review purchase={purchase} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const reset = () => {
    appContext.resetWalletState();
    setPurchase(initPurchase());
    setActiveStep(0);
  };

  const getCredentialId = (): string => {
    const attr = credential?.credential_offer_dict?.credential_preview?.attributes.filter(it => it.name === "id");
    return attr ? attr[0].value : "";
  }

  return (
    <Card title={t("app.components.NewPurchase.title")}>
      <Stepper alternativeLabel activeStep={activeStep} sx={{ pt: 1, pb: 2, mt: 1 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(`app.components.${label}.title`)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ?
        <>
          {submitting ? 
            <Container maxWidth="sm">
              <Alert severity="info" icon={<CircularProgress />} >
                <AlertTitle>{t("app.components.NewPurchase.submittingTitle")}</AlertTitle>
                {t("app.components.NewPurchase.submittingMsg")}
              </Alert>
            </Container>
            :
            <>
              { error ? 
                <Container maxWidth="sm">
                  <Alert severity="error">
                    <AlertTitle>{t("app.components.NewPurchase.submittingError")}</AlertTitle>
                    {t("app.components.NewPurchase.submittingErrorMsg")}
                  </Alert>
                </Container>
                :
                <>
                  <Container maxWidth="sm">
                    <Typography variant="h5" gutterBottom>
                      {t("app.components.NewPurchase.confirmed")}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t("app.components.NewPurchase.confirmedMsg") + getCredentialId()}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t("app.components.NewPurchase.eReceiptSentMsg")}
                    </Typography>
                  </Container>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      onClick={reset}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {t("app.components.NewPurchase.title")}
                    </Button>
                  </Box>
                </>
              }
            </>
          }
        </>
        :
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                {t("app.components.NewPurchase.previousStep")}
              </Button>
            )}
            <Button
              variant="contained"
              disabled={activeStep === 1 && !appContext.connection}
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
            >
              {activeStep === steps.length - 1 ? t("app.components.NewPurchase.confirm") : t("app.components.NewPurchase.nextStep")}
            </Button>
          </Box>
        </>
      }
    </Card>
  );
}

export default NewPurchase;