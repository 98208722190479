import React from "react";
import { useTranslation } from "react-i18next";
import AddConnection from "./AddConnection";
import NewPurchase from "./NewPurchase";

const Home: React.FC = () => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <NewPurchase />
    </>
  );
}

export default Home;