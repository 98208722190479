import { Container, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Purchase } from "../models/PurchaseModels";

interface Props {
  purchase: Purchase,
}

const Review: React.FC<Props> = ({purchase}) => {
  const { t } = useTranslation(["translation"]);

  const getTotalPrice = () => {
    function getSum(total: string, num: string) {
      const sum = parseFloat(total) + parseFloat(num);
      return sum.toFixed(2);
    }
    return purchase.items.map(p => p.product.grossPrice).reduce(getSum, "0");
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        {t("app.components.Review.title")}
      </Typography>
      <List disablePadding>
        {purchase.items.map((item) => (
          <ListItem key={item.product.sku} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={t(`app.components.products.${item.product.sku}.name`)} secondary={t(`app.components.products.${item.product.sku}.desc`)} />
            <Typography variant="body2">{item.product.grossPrice} €</Typography>
          </ListItem>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={t("app.components.Review.total")} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getTotalPrice()} €
          </Typography>
        </ListItem>
      </List>
    </Container>
  );
}

export default Review;