/** @jsxImportSource @emotion/react */
import React, { Suspense } from 'react';
import { css, Global } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { ContentContainer, HeaderBackground, TopNavigationBackground } from './components/common/CommonStyles';
import Header from './components/common/Header';
// const Home = lazy(() => import('./components/Home'));
import Home from './components/Home';
import AppContextProvider from './state/AppStateContext';

const styles = {
  contentContainer: css({
    padding: tokens.spacing.m,
    boxSizing: 'border-box',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1200px',
    '@media (min-width: 992px)': {
      padding: tokens.spacing.xxl
    }
  })
}

const App: React.FC = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <AppContextProvider>
      <Global styles={css`
        body {
          background-color: ${tokens.colors.depthLight3};
          font-family: ${tokens.values.typography.bodyText.fontFamily};
          font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: ${tokens.values.typography.bodyText.lineHeight.value}${tokens.values.typography.bodyText.lineHeight.unit};
          margin: 0;
        }
        a {
          color: ${tokens.colors.highlightBase};
          text-decoration: none; 
        }
        a:visited {
          color: ${tokens.colors.accentTertiaryDark1}
        }
        code {
          white-space: pre-wrap !important;
        }
        label {
          font-weight: ${tokens.values.typography.bodySemiBold.fontWeight}
        } 
        hr {
          border: 0;
          border-bottom: 1px solid ${tokens.colors.depthLight3};
          margin: ${tokens.spacing.m} 0;
        }
        ul, ol {
          margin: 0;
          padding: 0;
          @media (max-width: 1200px) {
            list-style-position: inside;              
          }
        }
        li {
          margin: 0;
          padding: 0;            
        }
        p {
          margin: ${tokens.spacing.m} 0;
        }
      `}
      />
      
      <Router>
        <HeaderBackground>
          <ContentContainer>
            <Header/>
          </ContentContainer>
        </HeaderBackground>

        <div css={styles.contentContainer}>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </AppContextProvider>
  </Suspense>
);

export default App;
